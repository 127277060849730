import React, { useEffect, useState } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import BackButtonComponent from "../backButton";
import TitleComponent from "./titleComponent";
import ReportsCSVTable from "./reportsCSVTable";
import FilerComponent from "./filerComponent";
import { getData, postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../Loader";

export default function ReportsCSVFilterComponent() {
  const location = useLocation();
  const data = location.state?.data;
  const [apiCallsInProgress, setApiCallsInProgress] = useState(0);
  const [, setReportsLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    allowExport: false,
    filterBtnLoading: false,
    exportBtnLoading: false,
    dates: {
      startDate: null,
      endDate: null,
    },
    individualFilterData: {},
    filterApiData: {},
    selectedClient: null,
  });
  const [clientFilterOptions, setClientFilterOptions] = useState([]);
  const [filterErrors, setFilterErrors] = useState("");
  const [groupId, setGroupId] = useState(0);
  const [dataUuid, setDataUuid] = useState(0);
  const [tableData, setTableData] = useState({
    headings: [],
    tableArrayData: [],
    grandTotalRow: [],
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [reportsDataLoading, setReportsDataLoading] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalPages = tableData.rows
    ? Math.ceil(tableData.rows.length / rowsPerPage)
    : 0;

  const handleApiStart = () => {
    setApiCallsInProgress((prev) => prev + 1);
    setReportsLoading(true);
  };

  const handleApiEnd = () => {
    setApiCallsInProgress((prev) => {
      const newCount = prev - 1;
      if (newCount === 0) {
        setReportsLoading(false);
      }
      return newCount;
    });
  };
  const handleGetUserInfo = async () => {
    handleApiStart();
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setGroupId(res?.data?.group_id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  const handleGetClientOptions = async () => {
    handleApiStart();
    const res = await getData(`report-client/${data?.id}`, {}, {});
    try {
      if (res?.status === true) {
        setClientFilterOptions(res?.data);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    handleGetClientOptions();
    handleGetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTableReportsData = async () => {
    handleApiStart();

    const request_uuid = dataUuid;
    const client_id = filterState.selectedClient
      ? filterState.selectedClient.id
      : 0;

    const url = `custom-report-details/${request_uuid}/${client_id}`;
    const res = await getData(url, {});
    try {
      if (res?.status === true) {
        const { headings, rows, grand_total_row } = res.data;
        const tableArrayData = rows.map((row) => {
          const rowData = {};
          headings.forEach((heading, index) => {
            rowData[heading[0]] = row.values[index][0];
          });
          return rowData;
        });
        setTableData({
          headings: headings.map((heading) => heading[0]),
          tableArrayData,
          grandTotalRow: grand_total_row,
        });
        setReportsDataLoading(false);
      } else {
        toast.error(res?.message);
        setReportsDataLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (dataUuid) {
      setTimeout(() => {
        getTableReportsData();
      }, 5000);
    }
  }, [dataUuid]);
  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Loader />
      ) : (
        <div className="reports-csv-filter-wrapper">
          <TitleComponent data={data} />
          <div className="back-btn-cus-width-wrapper">
            <BackButtonComponent reDirectionpath={`/reports?status=custom`} />
          </div>
          <div>
            <FilerComponent
              data={data}
              filterState={filterState}
              setFilterState={setFilterState}
              clientFilterOptions={clientFilterOptions}
              setFilterErrors={setFilterErrors}
              filterErrors={filterErrors}
              groupId={groupId}
              getTableReportsData={getTableReportsData}
              setDataUuid={setDataUuid}
              setReportsDataLoading={setReportsDataLoading}
              reportsDataLoading={reportsDataLoading}
            />
          </div>
          <div>
            {reportsDataLoading ? (
              <Loader />
            ) : (
              <div className="reports-csv-table-wrapper">
                <ReportsCSVTable
                  tableData={tableData}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handlePageChange={handlePageChange}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
