import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";

export default function DeleteModal(props) {
  const {
    delModalOpen,
    handleDelModalClose,
    deleteBtnLoading,
    deleteIndividualReports,
  } = props;

  return (
    <div className="details-modal-wrapper">
      <Modal
        show={delModalOpen}
        onHide={handleDelModalClose}
        className="modal-detail-wrapper"
      >
        <Modal.Header className="report-modal-header-wrapper">
          <Modal.Title>
            <h4>Delete Report</h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleDelModalClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-wrapper">
          Are you sure you want to close delete this report?
        </Modal.Body>
        <Modal.Footer className="modal-add-detail-main-wrapper column-footer-wrapper">
          <button
            className="btn-cancel-wrapper"
            onClick={deleteBtnLoading ? null : handleDelModalClose}
          >
            CANCEL
          </button>
          <button
            className="approve-btn-active"
            onClick={deleteBtnLoading ? null : deleteIndividualReports}
          >
            {deleteBtnLoading ? <Spinner /> : "Delete"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
