import React from "react";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";

function ClientSelect({
  clientOptions,
  selectedClientValues,
  setModalReportsData,
  errors,
  dropdownLoading,
}) {
  const handleSelectAllChange = (isChecked) => {
    const selectedKeys = clientOptions.map((option) => option.id);
    setModalReportsData((prevData) => ({
      ...prevData,
      selectedClientValues: isChecked ? selectedKeys : [],
    }));
  };

  const handleIndividualChange = (option, isChecked, type) => {
    const selectedKey =
      type === "reseller" ? "selectedResellerValues" : "selectedClientValues";

    setModalReportsData((prevData) => {
      const selectedOptions = prevData[selectedKey];
      const updatedOptions = isChecked
        ? [...selectedOptions, option]
        : selectedOptions.filter((item) => item.id !== option.id);

      return {
        ...prevData,
        [selectedKey]: updatedOptions,
      };
    });
  };

  const customOption = (props) => {
    const type = props.selectProps.type;
    const selectedOptions = selectedClientValues;

    const isChecked = selectedOptions.some((item) => item.id === props.data.id);
    const isDisabled = props.data.is_select === 1;

    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isChecked || isDisabled}
          onChange={(e) => {
            e.preventDefault();
            handleIndividualChange(props.data, e.target.checked, type);
          }}
        />
        <span className="ms-2">{props.data.legal_name}</span>
      </components.Option>
    );
  };

  const customMenuList = (props) => {
    const allSelected = clientOptions.every((option) =>
      selectedClientValues.includes(option.id)
    );

    return (
      <components.MenuList {...props}>
        <div className="mx-2 my-2">
          <input
            type="checkbox"
            checked={allSelected}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
          <span className="ms-2">Select all</span>
        </div>
        {props.children}
      </components.MenuList>
    );
  };
  const getOptionLabel = (option) => option.legal_name;

  return (
    <div className="select-client-wrapper">
      <Form.Label className="form-label-wrapper">
        Select client(s)<span>*</span>
      </Form.Label>
      <Select
        isMulti
        placeholder="Select the Clients"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={clientOptions}
        value={clientOptions.filter((option) =>
          selectedClientValues.includes(option)
        )}
        components={{
          Option: customOption,
          MenuList: customMenuList,
        }}
        isLoading={dropdownLoading}
        getOptionLabel={getOptionLabel}
      />
      {errors.selectedClientValues && (
        <div style={{ color: "red" }}>{errors.selectedClientValues}</div>
      )}
    </div>
  );
}

export default ClientSelect;
