import React, { useEffect } from "react";
import { Modal, Form, FormControl, Spinner } from "react-bootstrap";
import ResellerSelect from "./resellerSelect";
import ClientSelect from "./clientSelect";
import closebutton from "../../assets/images/multi-card-approval/x.svg";

export default function SaveModal(props) {
  const {
    showSaveModal,
    handleCloseModal,
    saveBtnLoading,
    modalReportsData,
    setModalReportsData,
    errors,
    setErrors,
    groupId,
    resellerOptions,
    clientOptions,
    getClientDropdownOptions,
    dropdownLoading,
    createNewCustomReport,
  } = props;
  useEffect(() => {
    const flattenedClientOptions = Object.values(clientOptions);
    const initiallySelectedClients = flattenedClientOptions.filter(
      (option) => option.is_select === 1
    );
    setModalReportsData((prevData) => ({
      ...prevData,
      selectedClientValues: initiallySelectedClients,
    }));
  }, [clientOptions, setModalReportsData]);

  return (
    <div className="details-modal-wrapper">
      <Modal show={showSaveModal} onHide={handleCloseModal}>
        <Modal.Header className="report-modal-header-wrapper">
          <Modal.Title>
            <h4>Save Template</h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleCloseModal}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-wrapper">
          <div className="report-name-wrapper">
            <Form.Label className="form-label-wrapper">
              Report Name<span>*</span>
            </Form.Label>
            <FormControl
              as="textarea"
              placeholder="Enter report name"
              maxLength={50}
              rows={1}
              onInput={(e) => {
                const reportName = e.target.value.trim();
                setModalReportsData((prevData) => ({
                  ...prevData,
                  reportName: {
                    value: reportName,
                    charCount: reportName.length > 0 ? reportName.length : 0,
                  },
                }));
                if (reportName.length > 0) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    reportName: "",
                  }));
                }
              }}
            />
            {errors.reportName !== "" ? (
              <div style={{ color: "red" }}>{errors.reportName}</div>
            ) : null}
          </div>
          <div className="description-wrapper">
            <Form.Label className="form-label-wrapper">
              Report description<span>*</span>
            </Form.Label>
            <FormControl
              as="textarea"
              placeholder="Enter the description"
              maxLength={100}
              rows={3}
              onInput={(e) => {
                const reportDescription = e.target.value.trim();
                setModalReportsData((prevData) => ({
                  ...prevData,
                  reportDescription: {
                    value: reportDescription,
                    charCount:
                      reportDescription.length > 0
                        ? reportDescription.length
                        : 0,
                  },
                }));
                if (reportDescription.length > 0) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    reportDescription: "",
                  }));
                }
              }}
            />
            {errors.reportDescription !== "" ? (
              <div style={{ color: "red" }}>{errors.reportDescription}</div>
            ) : null}
            <div className="char-count">
              {modalReportsData.reportDescription.charCount}/100
            </div>
          </div>

          {groupId === 1 || groupId === 6 ? (
            <>
              {groupId === 1 && (
                <ResellerSelect
                  resellerOptions={resellerOptions}
                  selectedResellerValues={
                    modalReportsData.selectedResellerValues
                  }
                  setModalReportsData={setModalReportsData}
                  getClientDropdownOptions={getClientDropdownOptions}
                  errors={errors}
                />
              )}
              <ClientSelect
                clientOptions={clientOptions.flat()}
                selectedClientValues={modalReportsData.selectedClientValues}
                setModalReportsData={setModalReportsData}
                dropdownLoading={dropdownLoading}
                errors={errors}
              />
            </>
          ) : null}
          <p className="note-wrapper">
            Note: This report will now be available for all the selected
            clients.
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-add-detail-main-wrapper column-footer-wrapper">
          <button
            className="btn-cancel-wrapper"
            onClick={!saveBtnLoading ? handleCloseModal : null}
          >
            CANCEL
          </button>
          <button
            className="approve-btn-active"
            onClick={createNewCustomReport}
          >
            {saveBtnLoading ? <Spinner animation="border" size="sm" /> : "SAVE"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
