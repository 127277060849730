import React, { useEffect, useState } from "react";
import "./style.scss";
import TitleSection from "./titleSection";
import TabSection from "./tabSection";
import ReportsTable from "./reportsTable";
// import BackButtonComponent from "../backButton";
import FilterModal from "./filterModal";
import { getData, postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import Loader from "../Loader";
import DeleteModal from "./deleteModal";
import { useLocation, useNavigate } from "react-router-dom";
import ReportsManageModal from "./reportsManageModal";

export default function ReportsComponent() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const status = urlParams.get("status");
    if (status) {
      setReportStatus(status);
    }
  }, [location.search]);

  const [reportStatus, setReportStatus] = useState("standard");
  const [standardReportsData, setStandardReportsData] = useState([]);
  const [customReportsData, setCustomReportsData] = useState([]);
  const [filterState, setFilterState] = useState({
    showFilterModal: false,
    allowExport: false,
    filterBtnLoading: false,
    exportBtnLoading: false,
    dates: {
      startDate: null,
      endDate: null,
    },
    individualFilterData: {},
    filterApiData: {},
    selectedClient: null,
  });
  const [, setReportsLoading] = useState(false);
  const [isGustoUser, setIsGustoUser] = useState(0);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [deleteBtnLoading] = useState(false);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(0);
  const [deleteReportData, setDeleteReportData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showManageSaveModal, setShowManageSaveModal] = useState(false);
  const [manageSaveBtnLoading, setManageSaveBtnLoading] = useState(false);
  const [modalReportsData, setModalReportsData] = useState({
    reportName: {
      value: "",
      charCount: 0,
    },
    reportDescription: {
      value: "",
      charCount: 0,
    },
    selectAllResellers: false,
    selectAllClients: false,
    selectedResellerValues: [],
    selectedClientValues: [],
  });
  const [clientFilterOptions, setClientFilterOptions] = useState([]);

  const [errors, setErrors] = useState({
    reportName: "",
    reportDescription: "",
    selectedId: "",
    selectedResellerValues: "",
    selectedClientValues: "",
  });
  const [groupId, setGroupId] = useState(0);
  const [filterErrors, setFilterErrors] = useState("");
  const [modalOptions, setModalOptions] = useState({
    resellerOptions: [],
    clientOptions: [],
  });
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [reportsId, setReportsId] = useState(0);

  const handleReportsChange = (e) => {
    setReportStatus(e);
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("status", e);
    navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    setCurrentPage(0);
  };

  const handleApiStart = () => {
    setApiCallsInProgress((prev) => prev + 1);
    setReportsLoading(true);
  };

  const handleApiEnd = () => {
    setApiCallsInProgress((prev) => {
      const newCount = prev - 1;
      if (newCount === 0) {
        setReportsLoading(false);
      }
      return newCount;
    });
  };
  const handleFilterModalOpen = (data) => {
    handleGetClientOptions(data?.id);
    setFilterState((prevState) => ({
      ...prevState,
      showFilterModal: true,
      individualFilterData: data,
    }));
  };
  const handleFilterModalClose = () => {
    setFilterState({
      showFilterModal: false,
      allowExport: false,
      filterBtnLoading: false,
      exportBtnLoading: false,
      individualFilterData: {},
      filterApiData: {},
      dates: {
        startDate: null,
        endDate: null,
      },
    });
  };
  const handleDelModalOpen = (data) => {
    setDelModalOpen(true);
    setDeleteReportData(data);
  };
  const handleDelModalClose = () => {
    setDelModalOpen(false);
  };
  const handleError = (message) => {
    handleApiEnd();
    toast.error(message, { theme: "colored", autoClose: autocloseTiming });
  };
  const handleSuccess = (message) => {
    toast.success(message, { theme: "colored", autoClose: 3000 });
  };
  const getGustoUserDetails = async () => {
    handleApiStart();
    const res = await getData("check-gusto-client", {});
    try {
      if (res?.status === true) {
        setIsGustoUser(res?.is_gusto);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(res.message);
    } finally {
      handleApiEnd();
    }
  };
  const getCustomReportsData = async () => {
    handleApiStart();
    const res = await getData("list-custom-report", {});
    try {
      if (res.status === true) {
        setCustomReportsData(res.data);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      handleApiEnd();
    }
  };
  const getStandardReportsData = async () => {
    handleApiStart();
    const res = await getData("standard-report", {});
    try {
      if (res.status === true) {
        setStandardReportsData(res.data);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    getGustoUserDetails();
    getStandardReportsData();
    getCustomReportsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteIndividualReports = async () => {
    handleApiStart();
    const res = await getData(`delete-report/${deleteReportData?.id}`, {});
    try {
      if (res.status === true) {
        handleSuccess(res.message);
        handleDelModalClose();
        const updatedCustomReports = customReportsData.filter(
          (report) => report.id !== deleteReportData?.id
        );
        setCustomReportsData(updatedCustomReports);
        const totalCustomReports = updatedCustomReports.length;
        const newTotalPages = Math.ceil(totalCustomReports / rowsPerPage);
        if (currentPage >= newTotalPages && newTotalPages > 0) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
      } else {
        handleError(res.message);
        handleApiEnd();
      }
    } catch (err) {
      handleError(err);
      handleApiEnd();
    } finally {
      handleApiEnd();
    }
  };
  const reportsData =
    reportStatus === "standard" ? standardReportsData : customReportsData;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalPages = Math.ceil(reportsData.length / rowsPerPage);
  const handleCloseModal = () => {
    setShowManageSaveModal(false);
    setManageSaveBtnLoading(false);
    setModalReportsData({
      reportName: {
        value: "",
        charCount: 0,
      },
      reportDescription: {
        value: "",
        charCount: 0,
      },
      selectedResellerValues: [],
      selectedClientValues: [],
      selectAllResellers: false,
      selectAllClients: false,
    });
    setErrors({});
  };

  const handleManageModalOpen = (data) => {
    const selectedClientValues = modalOptions.clientOptions
      .flat()
      .filter((option) => data.client_id.includes(option.id));

    setModalReportsData((prevData) => ({
      ...prevData,
      reportName: {
        value: data.name,
        charCount: data.name.length > 0 ? data.name.length : 0,
      },
      reportDescription: {
        value: data.description,
        charCount: data.description.length > 0 ? data.description.length : 0,
      },
      selectedResellerValues: data.reseller_id,
      selectedClientValues,
      selectAllResellers: false,
      selectAllClients: false,
    }));
    setReportsId(data?.id);
    setShowManageSaveModal(true);
  };
  const handleGetUserInfo = async () => {
    handleApiStart();
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setGroupId(res?.data?.group_id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (groupId === 1 || groupId === 6) {
      setReportStatus("custom");
    }
  }, [groupId]);
  const handleGetClientOptions = async (reportId) => {
    handleApiStart();
    const res = await getData(`report-client/${reportId}`, {}, {});
    try {
      if (res?.status === true) {
        setClientFilterOptions(res?.data);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };

  const getResellerDropdownOptions = async () => {
    handleApiStart();
    const res = await postData("get-gusto-reseller", {}, {});
    try {
      if (res?.status === true) {
        setModalOptions((prevData) => ({
          ...prevData,
          resellerOptions: res?.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  const getClientDropdownOptions = async () => {
    setDropdownLoading(true);
    const clientApiPayload = {
      reseller: modalReportsData?.selectedResellerValues,
    };
    const res = await postData("get-gusto-clients", {}, clientApiPayload);
    try {
      if (res?.status === true) {
        setModalOptions((prevData) => ({
          ...prevData,
          clientOptions: res?.data,
        }));
        setDropdownLoading(false);
      }
    } catch (err) {
      console.log(err);
      setDropdownLoading(false);
    } finally {
      // handleApiEnd();
      setDropdownLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserInfo();
    getResellerDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getClientDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalReportsData.selectedResellerValues]);
  const updateNewCustomReport = async () => {
    let hasErrors = false;
    if (modalReportsData.reportName.charCount === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportName: "Please enter the report name to create a template*",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportName: "",
      }));
    }
    if (modalReportsData.reportDescription.charCount === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportDescription:
          "Please enter the report description to create a template*",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportDescription: "",
      }));
    }
    // if (modalReportsData?.selectedResellerValues?.length === 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     selectedResellerValues:
    //       "Please select a reseller to create a template*",
    //   }));
    // } else {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     selectedResellerValues: "",
    //   }));
    // }
    if (modalReportsData?.selectedClientValues?.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedClientValues: "Please select a client to create a template*",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedClientValues: "",
      }));
    }

    if (hasErrors) {
      return;
    }
    setManageSaveBtnLoading(true);
    let updateObj = {
      report_id: reportsId,
      name: modalReportsData?.reportName?.value,
      description: modalReportsData?.reportDescription?.value,
      clients: modalReportsData?.selectedClientValues,
    };
    const res = await postData("assign-custom-report", {}, updateObj);
    try {
      if (res?.status === true) {
        setManageSaveBtnLoading(false);
        handleSuccess(res.message);
        handleCloseModal();
        getCustomReportsData();
      } else if (
        res?.status === false &&
        res?.message ===
          "A report already exists in the same name. Please rename it to create the report."
      ) {
        setManageSaveBtnLoading(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          reportName: res.message,
        }));
      } else {
        setManageSaveBtnLoading(false);
        handleError(res.message);
        handleCloseModal();
      }
    } catch (err) {
      setManageSaveBtnLoading(false);
      handleError(err);
      handleCloseModal();
    }
  };
  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Loader />
      ) : (
        <div className="reports-container">
          <TitleSection isGustoUser={isGustoUser} groupId={groupId} />
          {/* <BackButtonComponent hasHistoryback /> */}
          <div className="table-section-wrapper">
            {isGustoUser === 1 && groupId !== 4 ? (
              <TabSection
                reportStatus={reportStatus}
                handleReportsChange={handleReportsChange}
              />
            ) : null}
            <ReportsTable
              reportStatus={reportStatus}
              handleFilterModalOpen={handleFilterModalOpen}
              handleDelModalOpen={handleDelModalOpen}
              reportsData={reportsData}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={handlePageChange}
              endIndex={endIndex}
              startIndex={startIndex}
              totalPages={totalPages}
              currentPage={currentPage}
              handleManageModalOpen={handleManageModalOpen}
              groupId={groupId}
            />
          </div>
          <>
            <FilterModal
              filterState={filterState}
              setFilterState={setFilterState}
              handleFilterModalClose={handleFilterModalClose}
              clientFilterOptions={clientFilterOptions}
              setFilterErrors={setFilterErrors}
              filterErrors={filterErrors}
              groupId={groupId}
            />
          </>
          <>
            <DeleteModal
              handleDelModalClose={handleDelModalClose}
              delModalOpen={delModalOpen}
              deleteBtnLoading={deleteBtnLoading}
              deleteIndividualReports={deleteIndividualReports}
            />
          </>
          <>
            <ReportsManageModal
              handleCloseModal={handleCloseModal}
              showSaveModal={showManageSaveModal}
              saveBtnLoading={manageSaveBtnLoading}
              createNewCustomReport={updateNewCustomReport}
              modalReportsData={modalReportsData}
              setModalReportsData={setModalReportsData}
              errors={errors}
              setErrors={setErrors}
              groupId={groupId}
              resellerOptions={modalOptions?.resellerOptions}
              clientOptions={modalOptions?.clientOptions}
              getClientDropdownOptions={getClientDropdownOptions}
              dropdownLoading={dropdownLoading}
            />
          </>
        </div>
      )}
    </>
  );
}
