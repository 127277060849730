import React from "react";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";

function ResellerSelect({
  resellerOptions,
  selectedResellerValues,
  setModalReportsData,
  getClientDropdownOptions,
  errors,
}) {
  const handleSelectAllChange = (isChecked) => {
    const selectedKeys = resellerOptions.map((option) => option.id);
    setModalReportsData((prevData) => ({
      ...prevData,
      selectedResellerValues: isChecked ? selectedKeys : [],
    }));
  };

  const handleIndividualChange = (optionId, isChecked) => {
    setModalReportsData((prevData) => {
      const updatedOptions = isChecked
        ? [...prevData.selectedResellerValues, optionId]
        : prevData.selectedResellerValues.filter((id) => id !== optionId);

      return {
        ...prevData,
        selectedResellerValues: updatedOptions,
      };
    });
  };

  const customOption = (props) => {
    const isChecked = selectedResellerValues.includes(props.data.id);
    const isDisabled = props.data.is_select === 1;

    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isChecked || isDisabled}
          onChange={(e) => {
            e.preventDefault();
            handleIndividualChange(props.data.id, e.target.checked);
          }}
        />
        <span className="ms-2">{props.data.legal_name}</span>
      </components.Option>
    );
  };

  const customMenuList = (props) => {
    const allSelected = resellerOptions.every((option) =>
      selectedResellerValues.includes(option.id)
    );

    return (
      <components.MenuList {...props}>
        <div>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={(e) => {
              handleSelectAllChange(e.target.checked);
              getClientDropdownOptions();
            }}
          />
          <span className="ms-2">Select all</span>
        </div>
        {props.children}
      </components.MenuList>
    );
  };
  const getOptionLabel = (option) => option.legal_name;

  return (
    <div className="select-reseller-wrapper">
      <Form.Label className="form-label-wrapper">
        Select reseller(s)<span>*</span>
      </Form.Label>
      <Select
        isMulti
        placeholder="Select the Resellers"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={resellerOptions}
        value={resellerOptions.filter((option) =>
          selectedResellerValues.includes(option.id)
        )}
        components={{
          Option: customOption,
          MenuList: customMenuList,
        }}
        getOptionLabel={getOptionLabel}
        onChange={() => {
          getClientDropdownOptions(selectedResellerValues);
        }}
      />
      {errors.selectedResellerValues && (
        <div style={{ color: "red" }}>{errors.selectedResellerValues}</div>
      )}
    </div>
  );
}

export default ResellerSelect;
