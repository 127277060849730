import React from "react";
import { FormControl, FormGroup, Spinner } from "react-bootstrap";
import FilterIcon from "../../assets/images/reports_icons/filter_icon.svg";
import Select from "react-select";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { getData, postData } from "../../api";

export default function FilerComponent(props) {
  const {
    data,
    filterState,
    setFilterState,
    groupId,
    clientFilterOptions,
    filterErrors,
    setDataUuid,
    setReportsDataLoading,
    reportsDataLoading,
  } = props;
  const {
    dates,
    allowExport,
    filterBtnLoading,
    exportBtnLoading,
    filterApiData,
    selectedClient,
  } = filterState;
  const handleError = (message) => {
    toast.error(message, { theme: "colored", autoClose: autocloseTiming });
  };
  const handleSuccess = (message) => {
    toast.success(message, { theme: "colored", autoClose: 3000 });
  };
  const handleDateChange = (event, title) => {
    const value = event.target.value;
    setFilterState((prevState) => {
      if (title === "Start Date") {
        return {
          ...prevState,
          dates: {
            startDate: value,
            endDate:
              value &&
              prevState.dates.endDate &&
              value <= prevState.dates.endDate
                ? prevState.dates.endDate
                : null,
          },
          allowExport:
            value &&
            prevState.dates.endDate &&
            value === prevState.dates.startDate
              ? prevState.allowExport
              : false,
        };
      } else if (title === "End Date") {
        return {
          ...prevState,
          dates: {
            ...prevState.dates,
            endDate: value,
          },
          allowExport: value ? prevState.allowExport : false,
        };
      }
      return prevState;
    });
  };

  const handleEnableExport = async () => {
    setFilterState((prevState) => ({
      ...prevState,
      filterBtnLoading: true,
    }));
    setReportsDataLoading(true);
    const exportPayload = {
      report_id: data?.id,
      start_date: dates?.startDate,
      end_date: dates?.endDate,
      client_id: selectedClient ? selectedClient.id : 0,
    };
    const res = await postData("custom-report-data", {}, exportPayload);
    try {
      if (res.status === true) {
        setFilterState((prevState) => ({
          ...prevState,
          allowExport: true,
          filterBtnLoading: false,
          filterApiData: res.export,
        }));
        setDataUuid(res?.data?.request_uuid);
        handleSuccess(res.message);
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          allowExport: false,
          filterBtnLoading: false,
        }));
        handleError(res.message);
      }
    } catch (err) {
      setFilterState((prevState) => ({
        ...prevState,
        allowExport: false,
        filterBtnLoading: false,
      }));
      handleError(err);
    }
  };
  const handleExportToCsv = async () => {
    setFilterState((prevState) => ({
      ...prevState,
      exportBtnLoading: true,
    }));
    const client_id = selectedClient ? selectedClient.id : 0;
    const url = `report-export/${filterApiData?.request_uuid}/${client_id}`;
    const res = await getData(url, {});
    try {
      if (res.status === true) {
        const reportUrl = res.data.report_urls[0];
        window.open(reportUrl, "_blank");
        setFilterState((prevState) => ({
          ...prevState,
          exportBtnLoading: false,
        }));
        handleSuccess(res.message);
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          exportBtnLoading: false,
        }));
        handleError(res.message);
      }
    } catch (err) {
      setFilterState((prevState) => ({
        ...prevState,
        exportBtnLoading: false,
      }));
      handleError(err);
    }
  };
  return (
    <div className="filter-section-wrapper">
      <div className="filter-section-title-wrapper">
        <img src={FilterIcon} alt="filter-icon" />
        <h5>Filter Options</h5>
      </div>
      <div className="filter-section-select-wrapper">
        <div className="report-export-csv-wrapper ">
          <button
            className={
              allowExport && !reportsDataLoading
                ? "approve-btn-active btn-min-value-wrapper"
                : "disabled-export-csv-btn"
            }
            onClick={
              exportBtnLoading || !allowExport || reportsDataLoading
                ? null
                : handleExportToCsv
            }
          >
            {exportBtnLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "EXPORT TO CSV"
            )}
          </button>
        </div>
        {(groupId === 1 || groupId === 6) && (
          <div className="csv-client-selection-wrapper">
            <Select
              placeholder="Select the client"
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={clientFilterOptions.map((client) => ({
                label: client.legal_name,
                value: client,
              }))}
              onChange={(selectedOption) =>
                setFilterState((prevState) => ({
                  ...prevState,
                  selectedClient: selectedOption.value,
                }))
              }
            />{" "}
            {filterErrors !== "" && (
              <div style={{ color: "red" }}>{filterErrors}</div>
            )}
          </div>
        )}{" "}
        <div className="reports-download-filter-dates-wrapper">
          <FormGroup controlId="startDate" className="date-selector">
            <FormControl
              type="date"
              value={dates.startDate || ""}
              onChange={(event) => handleDateChange(event, "Start Date")}
            />
          </FormGroup>
          <FormGroup controlId="endDate" className="date-selector">
            <FormControl
              type="date"
              value={dates.endDate || ""}
              onChange={(event) => handleDateChange(event, "End Date")}
              disabled={!dates.startDate}
              min={dates.startDate}
            />
          </FormGroup>
        </div>
        <div className="reports-download-apply-btn">
          {" "}
          <button
            className={
              !dates.endDate
                ? "disabled-btn-wrapper"
                : "btn-cancel-wrapper btn-apply-filter"
            }
            onClick={!dates.endDate ? null : handleEnableExport}
          >
            {filterBtnLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "APPLY FILTER"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
